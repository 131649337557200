import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import Lines from 'components/lines'
import Dinamiza from 'components/colorDinamiza'
import Light from 'components/colorLight'
import style from 'scss/pages/posizioniAperte.module.scss'

class InformativaCookie extends React.Component{
  render() {

    const { location, data } = this.props

    return(
      <div ref={this.page} className={style.page}>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Cookie Policy" description={'Consulta la cookie policy del sito web di Dinamiza.'} />

          <Lines />

          <div className={`container-fluid ${style.page__content}`}>
            <div className={`row`}>
              <div className={`offset-md-2 col-md-2`}>
                <p className={`has-color-primary-negative ${style.page__content__claimLeft}`}><Light>Cookie policy</Light></p>
              </div>
              <div className={`offset-md-1 col-md-7 no-padding ${style.page__content__fullScreenSlide}`}>
                <div>

<p>Questo documento contiene informazioni in merito alle tecnologie che consentono a questa Applicazione di raggiungere gli scopi descritti di seguito. Tali tecnologie permettono al Titolare di raccogliere e salvare informazioni (per esempio tramite l&rsquo;utilizzo di Cookie) o di utilizzare risorse (per esempio eseguendo uno script) sul dispositivo dell&rsquo;Utente quando quest&rsquo;ultimo interagisce con questa Applicazione.</p>
<p>Per semplicit&agrave;, in questo documento tali tecnologie sono sinteticamente definite &ldquo;Strumenti di Tracciamento&rdquo;, salvo vi sia ragione di differenziare.<br />Per esempio, sebbene i Cookie possano essere usati in browser sia web sia mobili, sarebbe fuori luogo parlare di Cookie nel contesto di applicazioni per dispositivi mobili, dal momento che si tratta di Strumenti di Tracciamento che richiedono la presenza di un browser. Per questo motivo, all&rsquo;interno di questo documento il temine Cookie &egrave; utilizzato solo per indicare in modo specifico quel particolare tipo di Strumento di Tracciamento.</p>
<p>Alcune delle finalit&agrave; per le quali vengono impiegati Strumenti di Tracciamento potrebbero, inoltre richiedere il consenso dell&rsquo;Utente. Se viene prestato il consenso, esso pu&ograve; essere revocato liberamente in qualsiasi momento seguendo le istruzioni contenute in questo documento.</p>
<p>Questa Applicazione utilizza Strumenti di Tracciamento gestiti direttamente dal Titolare (comunemente detti Strumenti di Tracciamento &ldquo;di prima parte&rdquo;) e Strumenti di Tracciamento che abilitano servizi forniti da terzi (comunemente detti Strumenti di Tracciamento &ldquo;di terza parte&rdquo;). Se non diversamente specificato all&rsquo;interno di questo documento, tali terzi hanno accesso ai rispettivi Strumenti di Tracciamento.<br />Durata e scadenza dei Cookie e degli altri Strumenti di Tracciamento simili possono variare a seconda di quanto impostato dal Titolare o da ciascun fornitore terzo. Alcuni di essi scadono al termine della sessione di navigazione dell&rsquo;Utente.<br />In aggiunta a quanto specificato nella descrizione di ciascuna delle categorie di seguito riportate, gli Utenti possono ottenere informazioni pi&ugrave; dettagliate ed aggiornate sulla durata, cos&igrave; come qualsiasi altra informazione rilevante - quale la presenza di altri Strumenti di Tracciamento - nelle privacy policy dei rispettivi fornitori terzi (tramite i link messi a disposizione) o contattando il Titolare.</p>
<h3>Attivit&agrave; strettamente necessarie a garantire il funzionamento di questa Applicazione e la fornitura del Servizio</h3>
<p>Questa Applicazione utilizza Cookie comunemente detti &ldquo;tecnici&rdquo; o altri Strumenti di Tracciamento analoghi per svolgere attivit&agrave; strettamente necessarie a garantire il funzionamento o la fornitura del Servizio.</p>
<h3>Altre attivit&agrave; che prevedono l&rsquo;utilizzo di Strumenti di Tracciamento</h3>
<h4>Miglioramento dell&rsquo;esperienza</h4>
<p>Questa Applicazione utilizza Strumenti di Tracciamento per fornire una user experience personalizzata, consentendo una migliore gestione delle impostazioni personali e l'interazione con network e piattaforme esterne.</p>
<h3>Interazione con social network e piattaforme esterne</h3>
<p>Questo tipo di servizi permette di effettuare interazioni con i social network, o con altre piattaforme esterne, direttamente dalle pagine di questa Applicazione.<br />Le interazioni e le informazioni acquisite da questa Applicazione sono in ogni caso soggette alle impostazioni privacy dell&rsquo;Utente relative ad ogni social network.<br />Questo tipo di servizio potrebbe comunque raccogliere dati sul traffico per le pagine dove il servizio &egrave; installato, anche quando gli Utenti non lo utilizzano.<br />Si raccomanda di disconnettersi dai rispettivi servizi per assicurarsi che i dati elaborati su questa Applicazione non vengano ricollegati al profilo dell'Utente.</p>
<h4>Pulsante Mi Piace e widget sociali di Facebook (Facebook Ireland Ltd)</h4>
<p>Il pulsante &ldquo;Mi Piace&rdquo; e i widget sociali di Facebook sono servizi di interazione con il social network Facebook, forniti da Facebook Ireland Ltd</p>
<p>Dati Personali trattati: Dati di utilizzo e Strumento di Tracciamento.</p>
<p>Luogo del trattamento: Irlanda &ndash;&nbsp;<a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
<h3>Visualizzazione di contenuti da piattaforme esterne</h3>
<p>Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questa Applicazione e di interagire con essi.<br />Questo tipo di servizio potrebbe comunque raccogliere dati sul traffico web relativo alle pagine dove il servizio &egrave; installato, anche quando gli utenti non lo utilizzano.</p>
<h4>Google Fonts (Google Ireland Limited)</h4>
<p>Google Fonts &egrave; un servizio di visualizzazione di stili di carattere gestito da Google Ireland Limited che permette a questa Applicazione di integrare tali contenuti all&rsquo;interno delle proprie pagine.</p>
<p>Dati Personali trattati: Dati di utilizzo e Strumento di Tracciamento.</p>
<p>Luogo del trattamento: Irlanda &ndash;&nbsp;<a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
<h4>Misurazione</h4>
<p>Questa Applicazione utilizza Strumenti di Tracciamento per misurare il traffico e analizzare il comportamento degli Utenti con l'obiettivo di migliorare il Servizio.</p>
<h3>Servizi di statistica anonimizzata</h3>
<p>I servizi in questa sezione permettono al Titolare di elaborare e gestire statistiche in forma anonima grazie all&rsquo;uso di Strumenti di Tracciamento di prima parte.</p>
<h4>Google Analytics con IP anonimizzato (Google Ireland Limited)</h4>
<p>Google Analytics &egrave; un servizio di analisi web fornito da Google Ireland Limited (&ldquo;Google&rdquo;). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l&rsquo;utilizzo di questa Applicazione, compilare report e condividerli con gli altri servizi sviluppati da Google.<br />Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.<br />Questa integrazione di Google Analytics rende anonimo il tuo indirizzo IP. L'anonimizzazione funziona abbreviando entro i confini degli stati membri dell'Unione Europea o in altri Paesi aderenti all'accordo sullo Spazio Economico Europeo l'indirizzo IP degli Utenti. Solo in casi eccezionali, l'indirizzo IP sar&agrave; inviato ai server di Google ed abbreviato all'interno degli Stati Uniti.</p>
<p>Dati Personali trattati: Dati di utilizzo e Strumento di Tracciamento.</p>
<p>Luogo del trattamento: Irlanda &ndash;&nbsp;<a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>&nbsp;&ndash;&nbsp;<a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" rel="noopener">Opt Out</a>.</p>
<h3>Statistica</h3>
<p>I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell&rsquo;Utente.</p>
<h4>Google Analytics (Google Ireland Limited)</h4>
<p>Google Analytics &egrave; un servizio di analisi web fornito da Google Ireland Limited (&ldquo;Google&rdquo;). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l&rsquo;utilizzo di questa Applicazione, compilare report e condividerli con gli altri servizi sviluppati da Google.<br />Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.</p>
<p>Dati Personali trattati: Dati di utilizzo e Strumento di Tracciamento.</p>
<p>Luogo del trattamento: Irlanda &ndash;&nbsp;<a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>&nbsp;&ndash;&nbsp;<a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" rel="noopener">Opt Out</a>.</p>
<h4>Targeting e Pubblicit&agrave;</h4>
<p>Questa Applicazione utilizza Strumenti di Tracciamento per fornire contenuti commerciali personalizzati in base al comportamento dell'Utente e per gestire, diffondere e tracciare annunci pubblicitari.</p>
<h3>Pubblicit&agrave;</h3>
<p>Questo tipo di servizi consente di utilizzare i Dati dell&rsquo;Utente per finalit&agrave; di comunicazione commerciale. Queste comunicazioni sono mostrate su questa Applicazione sotto forma di banner e altre forme pubblicitarie, anche in relazione agli interessi dell&rsquo;Utente.<br />Ci&ograve; non significa che tutti i Dati Personali vengano utilizzati per questa finalit&agrave;. Dati e condizioni di utilizzo sono indicati di seguito.<br />Alcuni dei servizi di seguito indicati potrebbero utilizzare Strumenti di Tracciamento per identificare l&rsquo;Utente o utilizzare la tecnica del behavioral retargeting, ossia visualizzare annunci pubblicitari personalizzati in base agli interessi e al comportamento dell&rsquo;Utente, rilevati anche al di fuori di questa Applicazione. Per avere maggiori informazioni in merito, ti suggeriamo di verificare le informative privacy dei rispettivi servizi.<br />In aggiunta alle funzionalit&agrave; di opt-out offerte dai servizi di seguito riportati, l'Utente pu&ograve; effettuare l&rsquo;opt-out visitando la&nbsp;<a href="http://optout.networkadvertising.org/">pagina di opt-out della Network Advertising Initiative</a>.</p>
<p><strong>Gli Utenti possono anche scegliere di non partecipare a determinate funzionalit&agrave; pubblicitarie attraverso le corrispondenti opzioni di configurazione del dispositivo, come le opzioni di configurazione pubblicitaria del dispositivo mobile o la configurazione pubblicitaria generica</strong>.</p>
<h4>Reach (Reach International )</h4>
<p>Reach &egrave; un servizio di advertising fornito da Reach International.</p>
<p>Dati Personali trattati: Dati di utilizzo e Strumento di Tracciamento.</p>
<p>Luogo del trattamento: Emirati arabi uniti &ndash;&nbsp;<a href="http://www.reachadv.com/#anchor2" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
<h4>Google AdSense (Google Ireland Limited)</h4>
<p>Google AdSense &egrave; un servizio di advertising fornito da Google Ireland Limited. Questo servizio usa il Cookie &ldquo;DoubleClick&rdquo;, che traccia l&rsquo;utilizzo di questa Applicazione ed il comportamento dell&rsquo;Utente in relazione agli annunci pubblicitari, ai prodotti e ai servizi offerti.<br />L&rsquo;Utente pu&ograve; decidere in qualsiasi momento di disabilitare tutti i cookie DoubleClick andando su:&nbsp;<a href="https://adssettings.google.com/authenticated?hl=it">Impostazioni Annunci</a>.</p>
<p>Dati Personali trattati: Dati di utilizzo e Strumento di Tracciamento.</p>
<p>Luogo del trattamento: Irlanda &ndash;&nbsp;<a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>&nbsp;&ndash;&nbsp;<a href="https://adssettings.google.com/authenticated#display_optout">Opt Out</a>.</p>
<h3 id="manage_cookies">Come gestire le preferenze e prestare o revocare il consenso</h3>
<p>Esistono vari modi per gestire le preferenze relative agli Strumenti di Tracciamento e per prestare o revocare il consenso, ove necessario:</p>
<p>Gli Utenti possono gestire le preferenze relative agli Strumenti di Tracciamento direttamente tramite le impostazioni dei propri dispositivi - per esempio, possono impedire l&rsquo;uso o l&rsquo;archiviazione di Strumenti di Tracciamento.</p>
<p>In aggiunta, ogni qualvolta l&rsquo;utilizzo di Strumenti di Tracciamento dipenda da consenso, l&rsquo;Utente pu&ograve; prestare o revocare tale consenso impostando le proprie preferenze all&rsquo;interno dell&rsquo;informativa sui cookie o aggiornando tali preferenze tramite il widget delle impostazioni di tracciamento, se presente.</p>
<p>Grazie ad apposite funzioni del browser o del dispositivo &egrave; anche possibile rimuovere Strumenti di Tracciamento precedentemente salvati.</p>
<p>Altri Strumenti di Tracciamento presenti nella memoria locale del browser possono essere rimossi cancellando la cronologia di navigazione.</p>
<p>Per quanto riguarda Strumenti di Tracciamento di terza parte, gli Utenti possono gestire le preferenze e revocare il consenso visitando il relativo link di opt out (qualora disponibile), utilizzando gli strumenti descritti nella privacy policy della terza parte o contattandola direttamente.</p>
<h4>Individuare le impostazioni relative agli Strumenti di Tracciamento</h4>
<p>Gli Utenti possono, per esempio, trovare informazioni su come gestire i Cookie in alcuni dei browser pi&ugrave; diffusi ai seguenti indirizzi:</p>
<ul>
<li><a href="https://support.google.com/chrome/answer/95647?hl=it&amp;p=cpn_cookies" target="_blank" rel="noopener nofollow">Google Chrome</a></li>
<li><a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie" target="_blank" rel="noopener nofollow">Mozilla Firefox</a></li>
<li><a href="https://support.apple.com/it-it/guide/safari/manage-cookies-and-website-data-sfri11471/" target="_blank" rel="noopener nofollow">Apple Safari</a></li>
<li><a href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies" target="_blank" rel="noopener nofollow">Microsoft Internet Explorer</a></li>
<li><a href="https://support.microsoft.com/it-it/help/4027947" target="_blank" rel="noopener nofollow">Microsoft Edge</a></li>
<li><a href="https://support.brave.com/hc/articles/360022806212-How-do-I-use-Shields-while-browsing" target="_blank" rel="noopener nofollow">Brave</a></li>
<li><a href="https://help.opera.com/latest/web-preferences/#cookies" target="_blank" rel="noopener nofollow">Opera</a></li>
</ul>
<p>Gli Utenti possono inoltre gestire alcuni Strumenti di Tracciamento per applicazioni mobili disattivandoli tramite le apposite impostazioni del dispositivo, quali le impostazioni di pubblicit&agrave; per dispositivi mobili o le impostazioni relative al tracciamento in generale (gli Utenti possono consultare le impostazioni del dispositivo per individuare quella pertinente).</p>
<h4>Iniziative dell'industria pubblicitaria per la gestione dei consensi</h4>
<p>Fermo restando quanto precede, si informano gli Utenti della possibilit&agrave; di avvalersi delle informazioni presenti su&nbsp;<a href="http://www.youronlinechoices.eu/" target="_blank" rel="noopener nofollow">YourOnlineChoices</a>&nbsp;(EU),&nbsp;<a href="https://www.networkadvertising.org/understanding-digital-advertising" target="_blank" rel="noopener nofollow">Network Advertising Initiative</a>&nbsp;(USA) e&nbsp;<a href="https://www.aboutads.info/consumers/" target="_blank" rel="noopener nofollow">Digital Advertising Alliance</a>&nbsp;(USA),&nbsp;<a href="https://youradchoices.ca/understanding-online-advertising/" target="_blank" rel="noopener nofollow">DAAC</a>&nbsp;(Canada),&nbsp;<a href="http://www.ddai.info/optout" target="_blank" rel="noopener nofollow">DDAI</a>&nbsp;(Giappone) o altri servizi analoghi. Con questi servizi &egrave; possibile gestire le preferenze di tracciamento della maggior parte degli strumenti pubblicitari. Il Titolare, pertanto, consiglia agli Utenti di utilizzare tali risorse in aggiunta alle informazioni fornite nel presente documento.</p>
<p>La Digital Advertising Alliance mette inoltre a disposizione un&rsquo;applicazione chiamata&nbsp;<a href="https://youradchoices.com/appchoices" target="_blank" rel="noopener nofollow">AppChoices</a>&nbsp;che aiuta gli Utenti a controllare la pubblicit&agrave; comportamentale sulle applicazioni mobili.</p>
<h3 id="owner_of_the_data">Titolare del Trattamento dei Dati</h3>
<p>STARSOFTWARE SRL<br />Via B. Maderna, 7<br />Mestre, Venezia</p>
<p><strong>Indirizzo email del Titolare:</strong>&nbsp;info@dinamiza.it</p>
<p>Dal momento che l&rsquo;uso di Strumenti di Tracciamento di terza parte su questa Applicazione non pu&ograve; essere completamente controllato dal Titolare, ogni riferimento specifico a Strumenti di Tracciamento di terza parte &egrave; da considerarsi indicativo. Per ottenere informazioni complete, gli Utenti sono gentilmente invitati a consultare la privacy policy dei rispettivi servizi terzi elencati in questo documento.</p>
<p>Data l'oggettiva complessit&agrave; di identificazione delle tecnologie di tracciamento, gli Utenti sono invitati a contattare il Titolare qualora volessero ricevere ulteriori informazioni in merito all'utilizzo di tali tecnologie su questa Applicazione.</p>
<h3>Definizioni e riferimenti legali</h3>
<h4>Dati Personali (o Dati)</h4>
<p>Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.</p>
<h4>Dati di Utilizzo</h4>
<p>Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da applicazioni di parti terze integrate in questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall&rsquo;Utente che si connette con questa Applicazione, gli indirizzi in notazione URI (Uniform Resource Identifier), l&rsquo;orario della richiesta, il metodo utilizzato nell&rsquo;inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all&rsquo;itinerario seguito all&rsquo;interno dell&rsquo;Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all&rsquo;ambiente informatico dell&rsquo;Utente.</p>
<h4>Utente</h4>
<p>L'individuo che utilizza questa Applicazione che, salvo ove diversamente specificato, coincide con l'Interessato.</p>
<h4>Interessato</h4>
<p>La persona fisica cui si riferiscono i Dati Personali.</p>
<h4>Responsabile del Trattamento (o Responsabile)</h4>
<p>La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.</p>
<h4>Titolare del Trattamento (o Titolare)</h4>
<p>La persona fisica o giuridica, l'autorit&agrave; pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalit&agrave; e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questa Applicazione. Il Titolare del Trattamento, salvo quanto diversamente specificato, &egrave; il titolare di questa Applicazione.</p>
<h4>Questa Applicazione</h4>
<p>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.</p>
<h4>Servizio</h4>
<p>Il Servizio fornito da questa Applicazione cos&igrave; come definito nei relativi termini (se presenti) su questo sito/applicazione.</p>
<h4>Unione Europea (o UE)</h4>
<p>Salvo ove diversamente specificato, ogni riferimento all&rsquo;Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell&rsquo;Unione Europea e dello Spazio Economico Europeo.</p>
<h4>Cookie</h4>
<p>I Cookie sono Strumenti di Tracciamento che consistono in piccole porzioni di dati conservate all'interno del browser dell'Utente.</p>
<h4>Strumento di Tracciamento</h4>
<p>Per Strumento di Tracciamento s&rsquo;intende qualsiasi tecnologia - es. Cookie, identificativi univoci, web beacons, script integrati, e-tag e fingerprinting - che consenta di tracciare gli Utenti, per esempio raccogliendo o salvando informazioni sul dispositivo dell&rsquo;Utente.</p>
<hr />
<h4>Riferimenti legali</h4>
<p>La presente informativa privacy &egrave; redatta sulla base di molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.</p>
<p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa Applicazione.</p>
<p>Ultima modifica: 5 ottobre 2021</p>
<p><a title="iubenda - generatore di Privacy Policy" href="https://www.iubenda.com/it/generatore-di-privacy-e-cookie-policy" target="_top">iubenda</a>&nbsp;ospita questo contenuto e raccoglie solo&nbsp;<a href="http://www.iubenda.com/privacy-policy/19418041" target="_top">i Dati Personali strettamente necessari</a>&nbsp;alla sua fornitura.</p>
                
                </div>
                <div className={`offset-md-5 col-md-7 no-padding ${style.page__content__fullScreenSlide__arrow}`}></div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default InformativaCookie
